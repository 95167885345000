import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'preloader',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SigninPage.vue')
  }]
},{
  path: '/apply',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'apply',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ApplyForPermitPage.vue')
  }]
},{
  path: '/permithistory',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'permithistory',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PermitHistoryPage.vue')
  }]
},{
  path: '/dashboard',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DashboardPage.vue')
  }]
},{
  path: '/assets_products',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'assets_products',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/AssetsAndProductsPage.vue')
  }]
},{
  path: '/warehouses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'warehouses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/WarehousesPage.vue')
  }]
},{
  path: '/products',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'products',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ProductsPage.vue')
  }]
},{
  path: '/packages',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'packages',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PackagesPage.vue')
  }]
},{
  path: '/generateinvoice',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'generateinvoice',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/GenerateInvoicePage.vue')
  }]
},{
  path: '/representatives',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'representatives',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/RepresentativesPage.vue')
  }]
},{
  path: '/companyprofile',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/CompanyProfilePage.vue')
  }]
},{
  path: '/makepayment',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'makepayment',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/MakePaymentPage.vue')
  }]
},{
  path: '/transactionhistory',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'transactionhistory',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/TransactionHistoryPage.vue')
  }]
},
{
  path: '/signin',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'signin-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SigninPage.vue')
  }]
},
{
  path: '/signup',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'signup-page',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SignupPage.vue')
  }]
},
{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
